.pie-graph{
    svg{
        g{
            path{
                fill:$green;
            }
            &:nth-child(2){
                path{
                    fill:$lightGreen;
                }
            }
        }
    }
    &.orange,
    &.red{
        svg{
            g{
                path{
                    
                    fill:$peach;
                }
                &:nth-child(2){
                    path{
                        fill:#F0A276;
                    }
                }
            }
        }
    }
}