.help-and-support-view{
    .contact-view-top{
        margin-top: 2.5rem;
        display:flex;
        justify-content: space-between;
        h2{
            @include heading3();
        }
        .left-col{
            width:50%;
            max-width:50%;
            .col-top-wrap{
                display:flex;
                justify-content: space-between;
                a{
                    @include basicLink();
                }
            }
            form{
                width:100%;
                display:block;
                margin-top:1.5rem;
                .form-field{
                    display:block;
                    width:100%;
                    margin-bottom:1.5rem;
                    div{
                        width:100%;
                    }
                }
                
            }
        }
        .right-col{
            width:calc(50% - 5rem);
            max-width:calc(50% - 5rem);
            .column-interior-wrapper{
                background-color:#FFF;
                padding:1.5rem;
                margin-bottom:1.5rem;
                box-shadow: 0px 3.40075px 6.8015px rgba(20, 23, 33, 0.04), 0px 0px 1.70037px rgba(20, 23, 33, 0.06), 0px 0px 0.850187px rgba(20, 23, 33, 0.04);
                h2{
                    @include sectionLabel();
                }
                .section-label{
                    display:block;
                    margin-top:1.25rem;
                    &:nth-child(1){
                        margin-top:0;
                    }
                }
                p{
                    font-size:1rem;
                }
                a{
                    @include basicLink();
                    font-size:1rem;
                }
            }
            
        }
    }
    @media screen and (max-width:$smallDesktop){
        .contact-view-top{
            .right-col{
                width:calc(50% - 2.5rem);
                max-width:calc(50% - 2.5rem);
            }
        }
    }
    @media screen and (max-width:$mobile){
        .contact-view-top{
            flex-direction:column;
            margin-top:24px;
            h2{
                font-size:18px;
            }
            .left-col,
            .right-col{
                width:100%;
                max-width:100%;
                .site-button.large{
                    width:100%;
                }
            }
            .right-col{
                margin-top:24px;
                .column-interior-wrapper{
                    margin-bottom:12px;
                }
            }
        }
    }
}