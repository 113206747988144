.dashboard-card{
    background-color:#FFF;
    box-shadow: 0px 3.40075px 6.8015px rgba(20, 23, 33, 0.04), 0px 0px 1.70037px rgba(20, 23, 33, 0.06), 0px 0px 0.850187px rgba(20, 23, 33, 0.04);
    padding:1.25rem 1.25rem 0;
    min-width:calc(25% - 1.125rem);
    max-width:calc(25% - 1.125rem);
    text-decoration:none;
    color:$darkest;
    display:flex;
    flex-direction:column;
    .card-top{
        display:flex;
        align-items:center;
        justify-content:space-between;
        margin-bottom:2.875rem;
        .heading-5{
            padding-right:1rem;
        }
        svg{
            fill: $darkest;
            opacity: 0.5;
            display: block;
            height: auto !important;
            width: 1.125rem;
            max-height: 1.125rem;
        }
    }
    .card-text{
        margin-bottom:1.25rem;
        .first-row{
            display:flex;
            align-items:flex-end;
            .first-metric{
                font-size:2.5rem;
                font-weight:700;
                letter-spacing:-0.02em;
                line-height:0.7;
            }
            p{
                line-height:1;
            }
        }
        .second-row{
            font-size:0.875rem;
            font-weight:500;
            margin-top:0.75rem;
            line-height: 1.14;
        }
    }
    .arrow-svg{
        display:block;
        width:1rem;
        height:auto;
        margin-bottom: 1.75rem;
    }

    .status-bar{
        background-color:$lightGreen;
        height: 0.25rem;
        width:calc(100% + 2.5rem);
        margin-left:-1.25rem;
        margin-top:auto;
        &.orange{
            background-color:  #F0A276;
            .status{
                background-color: $peach;
            }
        }
        .status{
            height:100%;
            background-color:$green;
        }
    }
    @media screen and (max-width:$tabletLandscape){
        min-width: calc(50% - 0.75rem);
        max-width: calc(50% - 0.75rem);
        &:nth-child(n+3){
            margin-top:1.5rem;
        }
    }
    @media screen and (max-width:$tabletPortrait){
        min-width:calc(25% - 1.125rem);
        max-width:calc(25% - 1.125rem);
        &:nth-child(n+3){
            margin-top:0;
        }
    }
    @media screen and (max-width:$mobile){
        min-width:100%;
        max-width:100%;
        &:nth-child(n+2){
            margin-top:1.5rem;;
        }
    }
}