.rules-management-view{
    .rules-listing{
        margin-top:2rem;
        display:flex;
        flex-wrap:wrap;
        justify-content:space-between;
        .rules-card{
            width: calc(50% - 0.75rem);
            &:nth-child(n+3){
                margin-top:1.5rem;
            }
        }
    }
    @media screen and (max-width:$mobile){
        .rules-listing{
            margin-top:20px;
            flex-direction:column;
            .rules-card{
                width:100%;
                &:nth-child(n+2){
                    margin-top:20px;
                }
            }
        }
    }
}