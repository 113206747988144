.login-form{
    form{
        .fields-wrap{
            display:flex;
            flex-direction:column;
            
        }
        .terms-note{
            margin-top:1rem;
        }
        .terms-checkbox{
                margin-top:0.25rem;
                &.invalid{
                    border:1px solid red;
                    width:calc(100% + 9px);
                }
                .MuiFormControlLabel-label{
                    color:#041E42;
                    opacity:0.87;
                    span{
                        text-decoration:underline;
                        cursor:pointer;
                        
                    }
                }     
        }
        .keep-me-logged-in{
            .MuiFormControlLabel-label{
                color:#041E42;
                opacity:0.87;
            }     
        }
    }
}