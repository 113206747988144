.network-health-view{
    .network-health-lisiting{
        margin-top: 2rem;
        display:flex;
        flex-wrap:wrap;
        justify-content:space-between;
        .network-health-card{
            width: calc(50% - 0.75rem);
            &:nth-child(n+3){
                margin-top:1.5rem;
            }
        }
    }
    @media screen and (max-width:$tabletLandscape){
        .network-health-lisiting{
            .network-health-card{
                width:100%;
                &:nth-child(n+2){
                    margin-top:1.5rem;
                }
            }
        }
    }
}