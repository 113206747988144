.dashboard-view{
    .status-cards-list{
        display:flex;
        flex-wrap:wrap;
        justify-content:space-between;
        margin-top: 3rem;
        .status-card{
            width: calc(50% - 0.75rem);
            &:nth-child(n+3){
                margin-top:1.5rem;
            }
        }
    }
    .sites-section{
        margin-top: 4rem;
       
        .site-items-list{
            display:flex;
            flex-wrap:wrap;
            justify-content:space-between;
            margin-top:1rem;
            .site-card{
                width: calc(50% - 0.75rem);
                &:nth-child(n+3){
                    margin-top:1.5rem;
                }
            }
        }
    }
    @media screen and (max-width:$tabletPortrait){
        .status-cards-list,
        .site-items-list{
            .status-card,
            .site-card{
                width:100% !important;
                &:nth-child(n+2){
                    margin-top:1.5rem;
                }
            }
        }
    }
    @media screen and (max-width:$mobile){
        .status-cards-list,
        .sites-section{
            margin-top:2.25rem;
        }
    }
}