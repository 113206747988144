#drawer{
    position:fixed;
    height:100vh;
    top:0;
    right:0;
    width:100vw;
    background-color:#FFF;
    z-index:10;
    transform:translateX(100%);
    transition:transform 0.75s ease-in-out;
    overflow-y:scroll;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
    &::-webkit-scrollbar {
        display: none;
    }
    &.open{
        transform:translateX(0);
    }
    .drawer-top-row{
        display:flex;
        justify-content:flex-end;
        align-items:center;
        .clear-all{
            color: $blue2;
            font-size:0.825rem;
            cursor:pointer;
        }
        .close-drawer{
            background-color:transparent;
            border:none;
            width:1rem;
            height:1rem;
            padding:0;
            cursor:pointer;
            margin-left: 1rem;
            svg{
             display:block;
             width:100%;
             height:auto;  
            }
        }
    
    }
    &.notifications-drawer{
        width:36.625rem;
        .drawer-top-row{
            position:absolute;
            right:2.5rem;
            display:inline-flex;
        }
       .notifications-items{
            margin-top: 1.875rem;    
           .notification-item{
                border: 1px solid #E3E5E8;
                padding:1.5rem;
                &:nth-child(n+2){
                    margin-top:1.25rem;
                }
                .note-top-row{
                    display:flex;
                    align-items:center;
                    .heading-4{
                        flex:1;
                        padding-right:1rem;
                        font-weight:700;
                    }
                    .clear-item{
                        color: $blue2;
                        font-size:0.825rem;
                        cursor:pointer;
                    }
                    
                }
                .note-date{
                    font-size:0.75rem;
                    opacity: 0.75;
                    margin-top:0.5rem;
                    color:$darkest;
                }
                .note-content{
                    margin-top:0.75rem;
                    @include paragraph();
                    color: $darkest;
                }
           }
       }
    }

    &.network-health-detail-drawer,
    &.ran-zone-detail-drawer,
    &.subscriber-detail-drawer{
        width:50.5rem;
        
    }
    &.ran-zone-detail-drawer,
    &.network-health-detail-drawer{
        .drawer-top-row{
            min-width:100%;
            .status-detail{
                display:flex;
                margin-right:auto;
                align-items:center;
                .status-message{
                    color:$darkBlue;
                }
            }
        }
    }

    .interior{
       padding:2.25rem 2.5rem;
        
    }
    @media screen and (max-width:$tabletPortrait){
        &.network-health-detail-drawer,
        &.ran-zone-detail-drawer,
        &.subscriber-detail-drawer{
            width:42rem;
        }
    }
    @media screen and (max-width:$mobile){
        .drawer-top-row{
            position:absolute;
            right:24px;
            display:inline-flex;
        }
        &.notifications-drawer{
            width:100%;
            .drawer-top-row{
                right:24px;
            }
            .notifications-items{
                margin-top:20px;
                .notification-item{
                    padding:20px 24px 24px;
                    .note-top-row{
                        .heading-4{
                            font-size:16px;
                        }
                    }
                    .note-content{
                        font-size:13px;
                    }
                    
                }
            }
        }

        &.network-health-detail-drawer,
        &.ran-zone-detail-drawer,
        &.subscriber-detail-drawer{
            width:100%;
        }

        &.ran-zone-detail-drawer,
        &.network-health-detail-drawer{
            .drawer-top-row{
                position:static;
            }
        }

        .interior{
            padding:24px 24px 100px;
        }
    }
}