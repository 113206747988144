.loading-wrap{
    display:flex;
    position:absolute;
    height:100vh;
    width:100%;
    align-items:center;
    justify-content:center;
    z-index:12;
    top:0;
    right:0;
    width: calc(100vw - 15.5rem);
    .loading {
        display:block;
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 50%;
        animation: rotate 1s linear infinite;
        &:before {
            content: "";
            box-sizing: border-box;
            position: absolute;
            inset: 0px;
            border-radius: 50%;
            border: 0.5rem solid $yellow;
            animation: prixClipFix 2s linear infinite ;
        }
        
      }
      @media screen and (max-width:$tabletPortrait){
            width:100vw;
      }
}
@keyframes rotate {
    100%   {transform: rotate(360deg)}
  }

  @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
      75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
      100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
  }



