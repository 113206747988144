.login-view{
    display:flex;
    align-items:center;
    justify-content:center;
    min-height:100vh;
    position:relative;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    .password-update-alert{
        position:absolute;
        top: 2rem;
        right:2rem;
        width:378px;
        max-width:calc(100vw - 4rem);
        z-index:9999;
    }
    &:before,
    &:after{
        content:'';
        display:block;
        left:0;
        background-size:100% auto;
        background-position:left top;
        background-repeat:no-repeat;
        position:absolute;
        opacity:0.05;
    }
    &:before{
        width:32.5rem;
        height:12.375rem;
        background-image:url('../../images/login-shape1.svg');
        top:0;
    }
    &:after{
        top:20.25rem;
        background-image:url('../../images/login-shape2.svg');
        width:8.8125rem;
        height:7.875rem;
    }
    .logo-wrap{
        padding:1.75rem 0 0 2rem;
        position:absolute;
        top:0;
        left:0;
        img{
            width:$logoSize;
        }
    }
    .login-form-wrap{
        width: 31.825rem;
        max-width: 100%;
        
        p:not(.MuiFormHelperText-root){
            font-size:1rem;
            a{
                color:inherit;
            }
        }
        form{
            margin-top:1.75rem;
            .form-field{
                + .form-field{
                    margin-top:1rem;
                }
            }

            .form-footer{
                margin-top:2rem;
                display:flex;
                align-items:center;
                button{
                    margin-right: 1.1875rem;
                }
                &.spaced{
                    justify-content:space-between;
                }
            }
        }
        .extra-details{
            margin-top: 1.6785rem;
        }
    }

    @media screen and (max-width:$mobile){
        align-items:flex-start;
        min-height:0;
        padding-top:136px;
        .password-update-alert{
            position:absolute;
            right:1.5rem;
            top:5rem;
            max-width:calc(100vw - 3rem);
            
        }
        &:before{
            height:7.88rem;
            width: 15.625rem;
            background-image:url('../../images/login-shape-mobile.svg');
            transform:translateY(-10%);
        }
        &:after{
            display:none;
        }
        .login-form-wrap{
            
            form{
                margin-top: 1.5rem;
                .terms-checkbox{
                    margin-top:0.5rem;
                }
                .form-footer{
                    flex-direction:column-reverse;
                    align-items:flex-start;
                    margin-top:0;
                    .site-button{
                        margin-top:0.75rem;
                        width:100%;
                    }
                }
            }
            .extra-details{
                margin-top:0.875rem;
            }
            &.forgot-password-form-wrap,
            &.reset-password-form-wrap{
                form{
                    .form-footer{
                        margin-top:0.875rem;
                        flex-direction:column;
                        .site-button{
                            margin-bottom: 0.75rem;
                            margin-top:0;
                        }
                    }
                }
            }
        }
    }
}