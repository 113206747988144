.logged-in-top-bar{
    padding-bottom:1.875rem;
    .section-columns{
        display:flex;
        justify-content:space-between;
        @include notificationsStatusWrap();
        .location-status{
            font-size:1.125rem;
            font-weight:400;
            &.location-specific{
                font-weight:500;
            }
            .back-to-all{
                text-decoration:underline;
                font-weight:400 !important;
                cursor:pointer;
            }
        }
        .notifications-toggle{
            .bell-icon{
                path{
                    fill: $darkest;
                }
            }
        }
    }
    @media screen and (max-width:$tabletPortrait){
        display:none;
    }
}