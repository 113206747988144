.hide-tablet-portrait{
    @media screen and (max-width:$tabletPortrait){
        display:none !important;
    }
}

.show-tablet-portrait{
    display:none;
    @media screen and (max-width:$tabletPortrait){
        display:block !important;
    }
}

.show-mobile{
    display:none !important;
    @media screen and (max-width:$mobile){
        display:block !important;
    }
}

.site-button{
    &.MuiButton-root{
        @include siteButton();
    }
    @include siteButton();
}

.view-heading{
    @include heading1();
    + p{
        font-size:1rem;
    }
}

.heading-2{
    @include heading2();
}

.heading-3{
    @include heading3();
}

.heading-4{
    @include heading4();
}

.heading-5{
    @include heading5();
}

.status-circle{
    margin-right:0.5rem;
    width:1.75rem;
    height:1.75rem;
    svg{
        path{
            fill: $green;
        }
    }
    &.red{
        svg{
            path{
                fill:$peach;
            }
        }
    }
    &.yellow{
        svg{
            path{
                fill:$yellow;
            }
        }
    }
}

.section-label{
    @include sectionLabel();
}

.dev-note{
    color:red;
    font-weight:800;
    font-size:0.75rem;
}

.cts-data-grid{
    @include dataGrid();
}


