.faqs-section{
    margin-top:4.25rem;
    .section-top-row{
        display:flex;
        justify-content:space-between;
        align-items:center;
        .topic-filter-wrap{
            min-width: 11.25rem;
        }
    }
    .faq-items{
        margin-top: 1.5rem;
        .faq-item{
            padding:1.375rem 0;
            margin-bottom:0 !important;
            margin-top:0 !important;
            box-shadow:none !important;
            &:before{
                opacity:1;
            }
            .MuiAccordionSummary-content{
                margin:0 !important;
            }
            .MuiAccordionSummary-expandIconWrapper{
                svg{
                    path{
                        fill:$blue;
                    }
                }
            }
            .top-section{
                
                h3{
                    @include heading4();
                    color:$darkBlue;
                }
               
                .faq-topic{
                    opacity:0.5;
                    margin-bottom:0;
                    margin-top:0.5rem;
                }
            }
            .MuiCollapse-wrapper{
                .MuiAccordionDetails-root{
                    padding-top:0.5rem;
                    padding-bottom:0;
                    color:$darkBlue;
                }
                p{
                    font-size:1rem;
                    color:$darkBlue;
                    opacity:1;
                }
            }

        }
    }
    @media screen and (max-width:$mobile){
        margin-top:32px;
        .section-top-row{
            flex-direction:column;
            align-items:flex-start;
            h2{
                font-size:18px;
            }
            .topic-filter-wrap{
                margin-top:14px;
                .MuiInputLabel-formControl{
                    line-height:0.8 !important;
                }
                #faq-topic-select{
                    height:43px;
                    max-height:43px;
                    padding-top:0;
                    padding-bottom:0;
                }
            }
        }
        .faq-items{
            margin-top: 20px;
            .faq-item{
                
                .top-section{
                    
                    h3{
                        font-size:18px;
                    }
                }
            }
        }
    }
}