.ran-site-zones{
    background-color:#FFF;
    margin-top: 1.5rem;
    .ran-item-top{
        display:flex;
        padding:1.5rem;
        .site-status-wrap{
            padding-left:0.75rem;
            flex:1;
            display:flex;
            justify-content:flex-end;
            align-items:center;
            p{
                opacity:1;
            }
        }
    }
    .zones-lisiting{
        .zone-row{
            padding:1.5rem;
            border-top: 1px solid #E3E5E8;
            display:flex;
            .zone-col{
                flex:1;
                display:flex;
                justify-content:center;
                .uptime{
                    opacity:0.5;
                    color:$darkest;
                }
                &:nth-child(n+2){
                    padding-left:1rem;
                }
                &.col-1{
                    .interior{
                        min-width:16.75rem;
                        display:flex;
                        flex-direction:row-reverse;
                        align-items:center;
                        justify-content:flex-end;
                        .heading-4{
                            display:inline-flex;
                            align-items:flex-start;
                            white-space: nowrap;
                            .lock-icon{
                                margin-left:0.25rem;
                                margin-top:-0.125rem;
                                min-width:1.25rem;
                                max-width:1.25rem;
                                min-height:1.25rem;
                                max-height:1.25rem;
                            }
                        }
                        
                    }
                }
                &.col-2{
                    padding-left:3rem;
                    a{
                        color:$blue2;
                        font-size:1rem;
                    }
                    p{
                        display:inline-block;
                        margin-right:0.5rem;
                        + p{
                            margin-top:0;
                        }
                    }
                }
                &.col-1,
                &.col-2{
                    justify-content:flex-start;
                }
                &.col-3,
                &.col-4,
                &.col-2{
                    p{
                        color:$darkest;
                        opacity:0.75;
                        font-size:1rem;
                    }
                }
                &.col-5{
                    @include arrowLink();
                    font-size:0.875rem;
                    justify-content:flex-end;
                    white-space: nowrap;
                }
                .col-bottom{
                    display:flex;
                    align-items:center;
                }
                .ran-zone-label{
                    font-weight:700;
                    font-size:0.75rem;
                    text-transform:uppercase;
                    color:$darkest;
                }
            }
        }
    }
    @media screen and (max-width: $tabletLandscape){
        .zones-lisiting{
            .zone-row{
                .zone-col{
                    &.col-1{
                        
                    }
                    &.col-2{
                        padding-left:0;
                        margin-left:2rem;
                    }
                }
            }
        }
    }

    @media screen and (max-width:$mobile){
        .ran-item-top{
            display:block;
            padding:20px 20px 22px;
            .site-status-wrap{
                justify-content:flex-start;
                padding-left:0;
            }
        }
        .zones-lisiting{
            .zone-row{
                padding:20px;
                flex-wrap:wrap;
                justify-content:flex-start;
                .zone-col{
                    flex:0 1 auto;
                    margin-bottom:20px;
                    &.col-1{
                        min-width:100%;
                    }
                    &.col-2{
                        padding-left:0;
                        margin-left:0;
                        min-width:100%;
                    }
                    &.col-3{
                        padding-left:0;
                    }
                    &.col-5{
                        margin-bottom:0;
                        padding-left:0;
                        min-width:100%;
                        justify-content:flex-start;
                    }
                }
            }
        }
    }
}