.filterable-grid,
.responsive-table{
    
    box-shadow:0px 3.40075px 6.8015px rgba(20, 23, 33, 0.04), 0px 0px 1.70037px rgba(20, 23, 33, 0.06), 0px 0px 0.850187px rgba(20, 23, 33, 0.04);
    border:none !important;
    .grid-toolbar{
        display:flex;
        justify-content:space-between;
        align-items:flex-start;
        position:relative;
        padding-bottom:1rem;
        .filters-and-chips{
            display:flex;
            align-items:flex-start;
            margin-left:0;
            .filter-button{
                flex-direction:row-reverse;
                text-transform:capitalize;
                color:$blue;
                svg{
                    margin-left: 0.75rem;
                }
            }
            .chips-wrap{
                margin-left:0.75rem;
                display:flex;
                flex-wrap:wrap;
                gap:0.5rem;
                .MuiChip-filled{
                    background-color: #DCEEFA;

                    .MuiChip-deleteIcon{
                        color: $blue;
                        opacity:0.7;
                    }
                    .filter-label{
                        text-transform:capitalize;
                    }
                }
            }
        }
    }
    .MuiDataGrid-main{
        @include dataGrid();
        
        .MuiDataGrid-virtualScrollerContent{
            height:auto !important;
            .MuiDataGrid-virtualScrollerRenderZone{
                position:static;
            }
        }
        
        .MuiDataGrid-renderingZone{
            max-height:none !important;
        }
        .MuiDataGrid-row{
            max-height:none !important;
            align-items:center;
        }
        .MuiDataGrid-cell{
            max-height:none !important;
            .group-select-wrapper{
              min-width:100%;
              max-width:100%;      
            } 
            .grid-select{
                border-radius:0 !important;
                width:100%;
                
                .MuiSelect-select{
                    width:calc(100% - 20px);
                    max-width:100%;
                    height:2rem;
                    display:block;
                    padding-top:0.25rem;
                    padding-bottom:0.25rem;
                    box-sizing:border-box;
                    color:#00000099;
                    opacity:0.6;
                }
                .MuiSvgIcon-root{
                    fill: $blue;
                }
            }
            .grid-autocomplete{
                width:100%;
                line-height:0;
                padding-top:0;
                padding-bottom:0;
                box-sizing:border-box;
                min-height:2rem;
                max-height:2rem;
                .MuiAutocomplete-endAdornment{
                    top:auto;
                    .MuiSvgIcon-root{
                        fill: $blue;
                        
                    }
                }
                
                .MuiOutlinedInput-root{
                    padding-top:0.125rem;
                    padding-bottom:0.125rem;
                    border-radius:0;
                    flex-wrap:nowrap;
                    overflow:hidden;
                    box-sizing:border-box;
                    .MuiChip-root{
                        background-color: #DCEEFA;
                        height:1.5rem;
                        max-height:1.5rem;
                        &:nth-child(n+2){
                            margin-left:0.25rem;
                        }
                        .MuiChip-label{
                            font-size:0.8125rem;
                        }
                    }
                }
                
            }
       }
    }
    .MuiDataGrid-footerContainer{
        background-color:#FFF;
    }
    @media screen and (max-width:$mobile){
        .grid-toolbar{
            padding-bottom:12px;
            .filters-and-chips{

            }
            .filter-grid-search{
                display:none !important;
            }
        }
    }
}