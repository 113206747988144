.sim-activation-content{
    text-align:center;
    .status-icon-wrap{
        height:3.75rem;
        margin-bottom:2.25rem;
        svg{
            display:block;
            height:100%;
            width:auto;
            margin:0 auto;
        }
    }
    h2{
        @include heading3();
    }
    p{
        margin-top:0.75rem;
    }
    .site-button{
        margin-top:1.25rem;
    }
}