.modal-wrapper{
    position:fixed;
    width:100vw;
    height:100vh;
    z-index:13;
    opacity:0;
    visibility:hidden;
    transition:all 0.5s ease-in-out;
    top:0;
    left:0;
    
    &.visible-modal{
        opacity:1;
        visibility:visible;
        
    }
    &.image-modal{
        img{
            display:block;
            width:100%;
        }
    }
    .modal-window{
        .close-modal{
                background-color:transparent;
                border:none;
                position:absolute;
                width: 1rem;
                height: 1rem;
                padding:0;
                cursor:pointer;
                right:2.25rem;
                top:2.25rem;
                -webkit-appearance: none;
                border-radius: 0;
                svg{
                    display:block;
                    width:100%;
                    height:auto;
                }
        }
    }
    &:not(.terms-and-conditions){
        .modal-window{
         //  overflow:hidden;
        }
    }
    &.terms-and-conditions{
        .modal-window{
            padding:0;
            .terms-flex-wrap{
                display:flex;
                flex-direction:column;
                max-height:calc(100vh - 4.25rem);
                .terms-modal-row{
                    padding-left: 2.5rem;
                    padding-right:2.5rem;
                    box-sizing:border-box;
                    
                    &.header-row{
                        padding-top: 2.25rem;
                        padding-bottom:1.875rem;
                        border-bottom:1px solid #E3E5E8;
                        h2{
                            @include heading3();
                        }
                    }
                    &.terms-content{
                        p { opacity: 1; }
                        color: #041E42;
                        padding-top: 1.25rem;
                        padding-bottom:1.875rem;
                        overflow-y:scroll;
                        flex:1;
                        width:calc(100% - 0.1875rem);
                        margin-top: 0.625rem;
                        &::-webkit-scrollbar {
                            width: 4px;
                            border:none;
                          }
                          
                          /* Track */
                          &::-webkit-scrollbar-track {
                            opacity:0;
                          }
                          
                          /* Handle */
                          &::-webkit-scrollbar-thumb {
                            background: #C4C4C4;
                            border-radius:3px;
                          }
                        
                    }
                    &.acknowledge-row{
                        padding-top: 1.875rem;
                        padding-bottom:1.875rem;
                        border-top:1px solid #E3E5E8;
                        .site-button{
                            display:block;
                        }
                    }
                }
            }
        }
        @media screen and (max-width:$tabletPortrait){
 
            .modal-window{
                .terms-flex-wrap{
                    .terms-modal-row{
                        &.acknowledge-row{
                            padding-bottom:60px !important;
                        } 
                    }
                }
            }
        }
        // mobile styling specific to terms and conditions modal
        @media screen and (max-width:$mobile){
            .modal-window{
                max-height:calc(100vh - 48px);
                .terms-flex-wrap{
                    height:100%;
                    .terms-modal-row{
                        padding:24px !important;
                        &.acknowledge-row{
                            padding-bottom:110px !important;
                        } 
                    }
                }
            }
        }
    }
    .modal-overlay{
        width:100vw;
        height:100vh;
        background-color:rgba(0,0,0,0.25);
        position:absolute;
        z-index:2;
        top:0;
        left:0;
    }

    .modal-window{
        background-color:#FFF;
        padding:2.5rem;
        position:absolute;
        left:50%;
        top:50%;
        transform:translate(-50%, -50%);
        width:39.5rem;
        max-width:calc(100vw - 48px);
        padding:3.25rem;
        z-index:3;
        max-height:calc(100vh - 4.25rem);
        overflow:auto;
    }
    @media screen and (max-width:$mobile){
        &.add-user-modal{
            .modal-window{
                padding:32px 32px 40px;
                .add-user-form{
                    .text-field{
                        margin-bottom:24px;
                    }
                    .bottom-selectors-row{
                        flex-direction:column;
                        .select-sites{
                            margin-left:0;
                            margin-top: 24px;
                        }
                    }
                }
            }
        }
    }
}