.network-health-card{
    padding: 1.625rem 0 1.25rem;
    background-color:#FFF;
    box-shadow: 0px 3.40075px 6.8015px rgba(20, 23, 33, 0.04), 0px 0px 1.70037px rgba(20, 23, 33, 0.06), 0px 0px 0.850187px rgba(20, 23, 33, 0.04);
    .nhc-segment{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        &:nth-child(n+2){
            border-top: 1px solid #E3E5E8;
        }
        &.top-section{
            padding-bottom:1.5rem;
            .heading-and-status{
                display:flex;
                justify-content:space-between;
                align-items:center;
                .left-col{
                    .left-col-top{
                        display:flex;
                        align-items:center;
                        .heading-4{
                            padding-right:0.125rem;
                            font-weight:700;
                        }
                    }
                    
                    .status-circle{
                        width:1.75rem;
                        height:1.75rem;
                    }
                    .left-col-bottom{
                        margin-top:1.25rem;
                        .text-wrap{
                            @include subscribersOnline();
                            .tw-right{
                                p{
                                    font-weight:500;
                                }
                            }
                        }
                    }
                }
                .right-col{
                    .pie-graphs-wrap{
                        .pie-graph{
                            padding:0;
                            width: 4.75rem;
                            height:4.75rem !important;
                        }
                    }
                }
            }

            
        }
        &.middle-section{
            padding-top:1.25rem;
            padding-bottom:1.25rem;
            display:flex;
            flex-wrap:wrap;
            justify-content:space-between;
            .section-item{
                &:nth-child(n+2){
                    padding-left:0.5rem;
                    padding-right:0.5rem;
                }
                h4{
                    font-size:0.75rem;
                    line-height:1.16;
                    font-weight:700;
                    text-transform:uppercase;
                    color:$darkest;
                }
                p{
                    font-size:1rem;
                    line-height:1.1875rem;
                    margin-top:0.5rem !important;
                }
            }
        }
        &.bottom-section{
            padding-top:1.125rem;
            .view-details{
                @include arrowLink();
            }
        }
        
    }
    @media screen and (max-width:$mobile){
        padding:16px 0;
        .nhc-segment{
            &.top-section{
                padding-left:16px;
                padding-right:16px;
                .heading-and-status{
                    padding-bottom:8px;
                    .status-circle{
                        margin-right:0;
                    }
                }
                .pie-graphs-wrap{
                    flex-direction:column;
                    .pie-graph-item{
                        &:nth-child(n+2){
                            margin-left:0;
                            margin-top:16px;
                        }
                    }
                }
            }
            &.middle-section{
                @media screen and (max-width:500px){
                    justify-content:flex-start;
                    .section-item{
                       
                        &:nth-child(1){
                            margin-right:40px;
                        }
                        &:nth-child(n+2){
                            padding:0;
                        }
                        &:nth-child(2){
                            min-width:calc(100% - 80px);
                        }
                        &:nth-child(n+3){
                            padding:0;
                            margin-top:24px;
                        }
                        // &:nth-child(3){
                        //     margin-right:40px;
                        // }
                    }
                }
                
            }
        }
    }
}