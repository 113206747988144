.add-user-form{
    h2{
        @include heading3();
    }
    form{
        margin-top: 1.875rem;
        .text-field{
            width:100%;
            margin-bottom:1.875rem;
        }
        .bottom-selectors-row{
            display:flex;
            margin-bottom:1.875rem;
            svg{
                color:$blue;
            }
            #role-select{
                width:10.25rem;
                
            }
            .select-sites{
                flex:1;
                margin-left: 2rem;
                .MuiAutocomplete-endAdornment{
                    top:auto;
                }
                svg{
                    color:$blue;
                }
            }
        }
        .error-message{
            color:$orange;
            font-weight:600;
            margin-bottom:1rem;
        }
    }
    @media screen and (max-width:$mobile){
        
    }
}