.ran-zone-detail-content{
    padding-top: 2.125rem;
    .zone-title{
        @include heading1();
        display:flex;
        flex-wrap:wrap;
        .darker{
            display:flex;
            align-items:center;
            .lock-icon{
                min-width:1rem;
                max-width:1rem;
                min-height:1.325rem;
                max-height:1.325rem;
                margin-left: 0.75rem;
            }
        }
        
    }
    .subscribers-and-rules{
        display:flex;
        gap:7rem;
        margin-top:2rem;
        .groups-column,
        .subscribers-col{
            h3{
                @include heading4();
            }
            .subscribers-online{
                font-size:2.5rem;
                font-weight:500;
                color:$darkest;
                span{
                    font-size:1rem;
                    font-weight:400;
                }
            }
            .arrow-link{
                @include arrowLink();
            }
            .groups-list{
                margin-top: 1rem;
                display:flex;
                gap: 0.5rem;
                .group-chip{
                    background-color: #DCEEFA;
                }
            }
        }
    }
    .radios-listing{
        margin-top: 2.375rem;
        h3{
            @include heading4();
        }
        .unrounded-alert{
            margin-top:1rem;
            .MuiAlert-icon{
                align-items:center;
            }
        }
        .radios-items{
            margin-top:1.125rem;
            display:flex;
            flex-wrap:wrap;
            gap:1.5rem;
            .radio-item-card{
                border: 1px solid #E3E5E8;
                padding:1rem 1.25rem 1.125rem 1.25rem;
                min-width:calc(50% - 0.75rem);
                max-width:calc(50% - 0.75rem);
                position:relative;
                .status-circle{
                    position:absolute;
                    top:0.75rem;
                    right:1.125rem;
                    margin-right:0;
                }
                .status{
                    position:absolute;
                    top:1rem;
                    right:1rem;
                    line-height: 1.25rem;
                    font-size:0.75rem;
                    padding:0 0.375rem;
                    color:rgba(0, 0, 0, 0.87);
                    border-radius: 4.06rem;
                }
                h5{
                    @include paragraph;
                    font-weight:400;
                    opacity:1;
                    color:$darkest;
                }
            }
        }
    }
    @media screen and (max-width:$mobile){
        padding-top:24px;
        .zone-title{
            font-size:20px;
            flex-direction:column;
            span{
                display:block;
                &.darker{
                    .lock-icon{
                        min-width:0.8125rem;
                        max-width:0.8125rem;
                        min-height:1.125rem;
                        max-height:1.125rem;
                        margin-left:0.25rem;
                    }
                }
            }
        }
        .subscribers-and-rules{
            display:block;
            .subscribers-col,
            .groups-column{
                h3{
                    font-size:14px;
                }
                .subscribers-online{
                    font-size:28px;
                    color:$darkest;
                    
                }
            }
            .groups-column{
                margin-top: 22px;
            }
        }
        .radios-listing{
           
            h3{
                font-size:14px;
            }
            .unrounded-alert{
                margin-top:12px;
            }
            .radios-items{
                margin-top:12px;
                display:block;
                .radio-item-card{
                    min-width:100%;
                    max-width:100%;
                    &:nth-child(n+2){
                        margin-top:12px;
                    }
                }
            }
        }
    }
    
}