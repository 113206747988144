.network-health-detail{
    padding-top:2.125rem;
    padding-left:0.5rem;
    .detail-title{
        @include heading1();
        span{
            font-size:0.875rem;
            opacity: 0.5;
            display:inline-block;
            margin-left:0.5rem;
            font-weight:400;
        }
    }
    .subscribers-online-details{
        @include subscribersOnline();
        .tw-right{
            p{
                font-weight:500;
            }
        }
        margin-top:2.5rem;
    }
    .network-detail-specifics{
        list-style-type:none;
        padding-left:0;
        margin-top:2rem;
        color:$darkest;
        li{
            font-size:1rem;
            line-height:1.5rem;
            + li{
                margin-top:1rem;
            }
            span{
                font-weight:600;
            }
        }
    }
    @media screen and (max-width:$mobile){
        padding-left:0;
        .detail-title{
            font-size:20px;
        }
        .pie-graphs-wrap{
            margin-top:14px;
            flex-wrap:wrap;
            gap:12px 0;
            .pie-graph-item{
                &:nth-child(1){
                    margin-right:20px;
                }
                &:nth-child(2){
                    margin-left:0;
                }
            }
        }
        .network-detail-specifics{
            margin-top:28px;
            li{
                font-size:13px;
                + li{
                    margin-top:13px;
                }
            }
        }
    }
}