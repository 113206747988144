.connected-devices{
    
    h2{
        color:$darkest;
    }
    .status-cards-row{
        display:flex;
        width:100%;
        gap:1.5rem;
        margin-top:2rem;
        margin-bottom:3.25rem;
        .status-card{
            width:calc(33.3333% - 1rem);
            min-width:300px;
        }
    }
    .subscribers-section{
        margin-bottom:2.25rem;
        position:relative;
        h2{
            margin-bottom:0.5rem;
        }
        .group-update-alert{
            width:auto;
            display:inline-flex;
            right:-3.5rem;
            bottom:1.25rem;
            position:absolute;
        }
    }
    .available-sims-section{
      .top-row{
          display:flex;
          justify-content:space-between;
          align-items:center;
      }
        .responsive-table{
            margin-top:1rem;
            
           
            
        }
    }
    @media screen and (max-width:$tabletPortrait){
        .status-cards-row{
            .status-card{
                width:calc(50% - 1rem);
            }
        }
    }
    @media screen and (max-width:$mobile){
        .status-cards-row{
            margin:20px 0 24px;
            flex-direction:column;
            gap:12px;
            .status-card{
                min-width:0;
                width:100%;
            }
        }
        .subscribers-section{
            h2{
                font-size:18px;
                margin-bottom:0;
            }
        }
        .available-sims-section{
            .top-row{
                h2{
                    font-size:18px;
                }
                .site-button{
                    display:none;
                }
            }

            .mobile-activate-sims-btn{
                width:calc(100% + 48px);
                margin-left:-24px;
                margin-top:25px;
                height:57px;
            }
        }
    }
}