.subscriber-detail-drawer{
    .subscriber-detail{
        padding-top: 1.875rem;
        .edit-icon{
            width:24px;
            height:24px;
            margin-left:12px;
        }
        .group-select-wrapper,
        .group-select{
            min-width:100%;
            max-width:100%;
            border-radius:0;
        }
        .detail-columns{
            width:100%;
            color:$darkBlue;
            .columns-row{
                display:flex;
                margin-top:2.5rem;
                justify-content:space-between;
                width:80%;
                .left-col,
                .right-col{
                    
                    width:calc(50% - 1.875rem);
                    min-width:calc(50% - 1.875rem);
                    h3:not(.section-label){
                        @include heading4();
                        margin-bottom:0.5rem;
                    }
                    h4{
                        @include heading2();
                        margin-bottom:0.75rem;
                    }
                }
                
            }
        }
    }
    @media screen and (max-width:$mobile){
        .subscriber-detail{
            padding-top:0;
            .view-heading{
               font-size:20px; 
            }
            .detail-columns{
                .columns-row:not(.type-and-site){
                    margin-top:20px;
                    flex-direction:column;
                    gap:20px;
                    .left-col,
                    .right-col{
                        width:100%;
                        min-width:100%;
                    }
                }
                .columns-row{
                    .left-col,
                    .right-col{
                        h4{
                            font-size: 16px;
                        }
                    }
                }
            }
            
        }
    }
}