.ran-view{
    .status-cards-row{
        margin-top: 2rem;
        display:flex;
        flex-wrap:wrap;
        justify-content:space-between;
        .status-card{
            width:calc(33.3333% - 1rem);
            max-width:calc(33.3333% - 1rem);
        }
    }
    .sites-section{
        margin-top:2.5rem;
    }
    @media screen and (max-width: $smallDesktop){
        .status-cards-row{
            .status-card{
                width:100%;
                max-width:100%;
                &:nth-child(n+2){
                    margin-top: 20px;
                }
            }
        }
    }
}