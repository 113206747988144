//Colors
$darkest: #041E42;
$darkBlue: #071E3E;
$blue: #3B6CA4;
$blue2: #426DA9;
$yellow:  #F5B948;
$green:#61BA43;;
$lightGreen: #C2F3A0;
$orange:#963821;
$peach:  #CF4520;

//Sizing
$logoSize: 5.8125rem;

//Fonts
$roboto: 'Roboto', sans-serif;;

//Breakpoints
$smallDesktop: 1299px;
$tabletLandscape: 1100px;
$tabletPortrait: 991px;
$mobile: 767px;