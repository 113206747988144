.system-status-card{
    background:#FFF;
    box-shadow:0px 3.40075px 6.8015px rgba(20, 23, 33, 0.04), 0px 0px 1.70037px rgba(20, 23, 33, 0.06), 0px 0px 0.850187px rgba(20, 23, 33, 0.04);
    width:calc(50% - 0.75rem);
    .top-row{
        padding:1.5rem 1.125rem 1.5rem 1.5rem;
        display:flex;
        align-items:center;
        justify-content:space-between;
        h3{
            @include heading4();
        }
        .status-dot-wrap{
            display:flex;
            align-items:center;
            .note{
                font-size:1rem;
                color:$darkest;
            }
        }
    }
    .alert-wrap{
        padding:0 0.375rem 0.375rem 0.375rem;
        
    }
    @media screen and (max-width:$mobile){
        width:100%;
        .top-row{
            padding:16px 16px 19px;
        }
        .alert-wrap{
            padding:0px 8px 9px;
        }
    }
}