@mixin siteButton($color: $darkBlue, $backgroundColor: $yellow){
    color:$color;
    background-color:$backgroundColor;
    text-transform:none;
    border-radius:0;
    &.large{
        height: 3.5rem;
        line-height:3.5rem;
        padding:0 1.375rem;
    }
    @media screen and (max-width:$mobile){
        height:2.625rem;
        line-height:2.625rem;
        padding-top:0;
        padding-bottom:0;
    }
}

@mixin heading1(){
    font-size:2rem;
    font-weight:700;
    line-height:1.18;
    color:$darkest;
    + p{
        margin-top:0.5rem;
    }
    .lighter{
        color: rgba(4, 30, 66, 0.56);
    }
    @media screen and (max-width:$mobile){
       font-size:1.75rem; 
    }
}

@mixin heading2(){
    font-size:1.5rem;
    font-weight:700;
    line-height:1.18;
    color:$darkest;
}

@mixin heading3(){
    font-size:1.25rem;
    font-weight:700;
    line-height:1.18;
    color:$darkest;
}

@mixin heading4(){
    font-size:1.125rem;
    font-weight:500;
    line-height:1.16;
    color:$darkest;
}

@mixin heading5(){
    font-size:1rem;
    font-weight:500;
    line-height:1.16;
    color:$darkest;
}

@mixin sectionLabel(){
    font-size:0.75rem;
    font-weight:700;
    text-transform:uppercase;
    color:$darkBlue;
    margin-bottom:0.75rem;
    color:$darkest;
}



@mixin paragraph($opacity: 0.75){
    font-size: 0.875rem;
    line-height:1.42rem;
    opacity:$opacity;
    + p{
        margin-top: 1rem;
    }
    a{
        color:inherit;
    }
    @media screen and (max-width:$mobile){
        + p{
            margin-top:0.875rem;
        }
    }
}

@mixin basicLink(){
    color: $blue;
    font-weight:500;
    cursor:pointer;
    font-size:0.8125rem;
    text-decoration:none;
}

@mixin arrowLink(){
    @include basicLink();
    display:flex;
    align-items:center;
    svg{
        display:block;
        margin-left: 0.75rem;
        width: 0.8125rem;
        .fill-here{
            fill:$blue;
            fill-opacity:1;
        }
    }
}

@mixin networkHealthPieGraphs(){
    display:flex;
    .pie-graph-item{
        display:flex;
        align-items:center;
        &:nth-child(n+2){
            margin-left:2.3125rem;
        }
        .pie-graph{
            padding:0;
            width: 2.5rem;
            height:2.5rem !important;
        }
        .text-wrap{
            padding-left:0.5rem;
            h3{
                font-size:1rem;
                font-weight:500;
                line-height:1.1875;
            }
            p{
                line-height:1.14;
            }
        }
        
    }
}

@mixin dataGrid(){
    background-color:#FFF;
    min-height:100px;
    .MuiDataGrid-columnsContainer{
     // position:static !important;
        // table header row
        .MuiDataGrid-columnHeaderWrapper{
            .MuiDataGrid-columnHeader{
                .MuiDataGrid-columnHeaderTitleContainer{
                    padding:0;
                }
                .MuiDataGrid-columnSeparator{
                   // display:none !important;  
                   opacity:0.5 !important; 
                }
            }
        }
        
    }
    
}

@mixin notificationsStatusWrap(){
    .notification-status-wrap{
        display:flex;
        align-items:center;
        .system-status{
            margin-right:1.25rem;
            opacity:1;
            font-size:1.125rem;
        }
        .notifications-toggle{
            position:relative;
            cursor:pointer;
            .bell-icon{
                height: 1.75rem;
                width:auto;
                display:block;
            }
            .count{
                width: 1.25rem;
                height: 1.25rem;
                border-radius:100%;
                background-color: #3B6CA4;
                position:absolute;
                top:0;
                right:0;
                color:#FFF;
                display:flex;
                align-items:center;
                justify-content:center;
                font-size:0.75rem;
                transform:translate(35%, -20%);
            }
        }
    }
}

@mixin subscribersOnline(){
    display:flex;
    align-items:flex-end;
    .tw-left{
        font-size:2.625rem;
        font-weight:700;
        letter-spacing:-0.08em;
        line-height:0.76;
        color: $darkest;
    }
    .tw-right{
        margin-left:0.5rem;
        p{
            margin:0;
            font-size:0.8125rem;
            color:$darkest;
            opacity: 0.75;
            line-height:1;
            &:nth-child(2){
                font-weight:500;
            }
        }
    }
}