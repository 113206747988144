.unrounded-alert{
    border-radius:0 !important;
    &.success{
        background:linear-gradient(0deg, #F4F5F8, #F4F5F8), #F44336 !important;
        color:$darkest;
        font-weight:500;
        .MuiAlert-icon{
            color:inherit !important;
        }
        
    }
    &.error{
        background: linear-gradient(0deg, #FCE7D1, #FCE7D1), #F44336;
        color:rgba(0,0,0,0.6);
        .MuiAlert-icon{
            color:$peach !important;
        }
    }
    
}