.status-card{
    padding:1.75rem;
    background-color:#FFF;
    display:flex;
    align-items:center;
    box-shadow: 0px 3.40075px 6.8015px rgba(20, 23, 33, 0.04), 0px 0px 1.70037px rgba(20, 23, 33, 0.06), 0px 0px 0.850187px rgba(20, 23, 33, 0.04);
    text-decoration:none;
    &.sitemap-card{
        cursor:pointer;
    }
    &.status-red{
        .pie-graph{
            
            svg{
                g{
                    path{
                        fill:$orange;
                    }
                    &:nth-child(2){
                        path{
                            fill:$peach;
                        }
                    }
                }
            }
        }
    }
    &.with-pie-chart{
        flex-direction:row-reverse;
    }
    .pie-graph,
    .img-wrap{
        min-width:4.875rem;
        max-width:4.875rem;
        height:4.875rem !important;
        margin-right:1rem;
        padding:0;
    }
    .img-wrap{
        background-color:#C4C4C4;
    }
    .card-text{
        padding-right:1rem;
        color:$darkBlue;
        @include subscribersOnline();
        flex:1;
        .heading-2{
            + p{
                margin-top: 0.25rem;
            }
        }
    }

    .arrow-svg{
        margin-left:auto;
        min-width:1rem;
        max-width:1rem;
        height:auto;
    }

    @media screen and (max-width:$mobile){
        padding:25px;
        .pie-graph{
            min-width:55px;
            max-width:55px;
            height:55px !important;
        }
        
    }
}