.site-card{
    background-color:#FFF;
    display:flex;
    align-items:center;
    cursor:pointer;
    padding:1.25rem 1.5rem;
    box-shadow: 0px 3.40075px 6.8015px rgba(20, 23, 33, 0.04), 0px 0px 1.70037px rgba(20, 23, 33, 0.06), 0px 0px 0.850187px rgba(20, 23, 33, 0.04);
    .card-text{
        p{
            opacity:0.5;
        }
    }
    .arrow-svg{
        margin-left:auto;
        width: 1rem;
        height: auto;
    }

    @media screen and (max-width:$tabletPortrait){
        .card-text{
            .heading-4{
                display:flex;
                .arrow-svg{
                    margin-left: 8px;
                    width:14px;
                }
            }
        }
    }
}