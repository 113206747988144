.system-status-view{
    .status-blocks-row{
        +.status-blocks-row{
            margin-top:2.875rem;
        }
        h2{
           @include heading2();
            
            + .row-cards{
                margin-top:1.25rem;
            }
        }
        .row-cards{
            margin-top:2rem;
            display:flex;
            justify-content:space-between;
        }
    }

    @media screen and (max-width:$mobile){

        .status-blocks-row{
            + .status-blocks-row{
                margin-top: 32px;
            }
            h2{
                font-size: 20px;
            }
            .row-cards{
                display:block;
                .system-status-card{
                    &:nth-child(n+2){
                        margin-top:12px;
                    }
                }
            }
        }
    }
}