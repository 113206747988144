.logged-in-view{
    display:flex;
    background-color:rgba(227, 229, 232, 0.1);
    min-height:100vh;
    padding-left:15.5rem;
    &:before{
        content:'';
        width:100vw;
        height:100vh;
        position:fixed;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index:9;
        background-color: $darkBlue;
        opacity:0;
        visibility:hidden;
        transition:all 0.75s ease-in-out;
    }
    &.drawer-open{
        &:before{
            opacity:0.5;
            visibility:visible;
        }
    }
    &:after{
        content:'';
        width:65.65vh;
        height:120vh;
        position:fixed;
        top:0;
        right:0;
        display:block;
        background-image:url('../../images/logged-in-rectangle.svg');
        background-size: auto 100%;
        background-position:right top;
        background-repeat:no-repeat;
        
    }

    
    .main-nav{
        background-color:$darkBlue;
        height:100vh;
        z-index:1;
        width:15.5rem;
        max-width:15.5rem;
        padding:1.75rem 2rem 6.1875rem;
        position:relative;
        position:fixed;
        left:0;
        top:0;
        display:flex;
        flex-direction:column;
        overflow-y:scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        @include notificationsStatusWrap();
        &::-webkit-scrollbar {
            display: none;
          }

        .notification-status-wrap{
            display:none;
            
        }
        .navicon{
            width:24px;
            display:none;
            flex-direction:column;
            justify-content:center;
            gap:5px;
            cursor:pointer;
            margin-right:44px;
            .line{
                height:3px;
                background-color:#FFF;
                display:block;
                &.line-2{
                    width:16px;
                    max-width:16px;
                }
                &.line-3{
                    width:9px;
                    max-width:9px;
                }
            }
        }
        .logo-wrap{
            width:$logoSize;
            max-width:$logoSize;
            img{
                display:block;
                max-width:100%;
            }
        }
        .sidebar-select{
            margin-top:1.875rem;
            width:100%;
            color:#FFF;
           
            svg{
                fill:#FFF;
            }
            fieldset{
                border-color:rgba(255,255,255,0.25);
                border-radius:0;
            }
        }
        .main-navigation-wrap{
            display:flex;
            flex-direction:column;
            flex:1;
            margin-top:2.625rem;
           
            .side-nav{
                list-style-type:none;
                padding-left:0;
                margin:0;
                &.secondary-menu-items{
                    margin-top:auto;
                    padding-top:1.75rem;
                }
                > li{
                    &:nth-child(n+2){
                        margin-top:1.75rem;
                    }
                    .alt-class{
                        color:$yellow !important; 
                    }
                    > a{
                        color:#FFF;
                        text-decoration:none;
                        display:flex;
                        font-size: 0.875rem;
                        font-weight:500;
                        &:hover{
                            color:$yellow !important;
                        }
                        &.active,
                        &.alt-class{
                            color:$yellow;
                        }
                        svg{
                            display:block;
                            height:auto !important;
                            width: 1.125rem;
                            max-height:1.125rem;
                            margin-right: 0.625rem;
                        }
                    }
                }
            }

        }
        
    }
    .main-content-col{
        width:calc(100vw - 15.5rem);
        margin-left:auto;
        position:relative;
        z-index:1;
        padding:2.125rem 5rem;
    }

    @media screen and (max-width:$smallDesktop){
        padding-left:0;
        .main-content-col{
            padding:2.125rem;
        }
    }
    
    @media screen and (max-width:$tabletPortrait){
        flex-direction:column;
        padding-top:136px;
        &:after{
            display:none;
        }
        .main-nav{
            position:fixed;
            top:0;
            left:0;
            z-index:2;
            width:100vw;
            max-width:100vw;
            height:auto;
            flex-direction:row;
            flex-wrap:wrap;
            padding:22px 24px 20px;
            overflow:visible;
            &.mobile-nav-open{
                .navicon{
                    display:block;
                    position:relative;
                    .line{
                        transform-origin:center;
                        left:0;
                        top:50%;
                        position:absolute;
                        
                        &.line-1{
                            width:100%;
                            max-width:100%;
                            transform:translateY(-50%) rotate(45deg);
                        }
                        &.line-2{
                            width:100%;
                            max-width:100%;
                            
                            transform:translateY(-50%) rotate(-45deg);
                        }
                        &.line-3{
                            display:none;
                        }
                    }
                }
                .sidebar-select{
                    &.mobile-hide-select{
                        display:flex !important;
                    }
                }
                .main-navigation-wrap{
                    display:flex;
                }
            }
            .navicon,
            .notification-status-wrap{
                display:flex;
            }
            .logo-wrap{
                flex:1;
                max-width:100%;
                img{
                    max-width: 93px;
                    margin:0 auto;
                }
            }
            .notification-status-wrap{
                .notifications-toggle{
                    svg{
                        path{
                            fill:#FFF;
                        }
                    }
                } 
            }
            .sidebar-select{
                width:calc(100vw - 48px);
                margin-top: 20px;
                &.mobile-hide-select{
                    display:none !important;
                }
                #location-select{
                    padding-top:9px;
                    padding-bottom:9px;
                    font-size:16px;
                }
            }
            .main-navigation-wrap{
                display:none;
                position:absolute;
                height:calc(100vh - 141px);
                z-index:999;
                background-color:$darkBlue;
                bottom:0;
                left:0;
                transform:translateY(100%);
                width:100vw;
                margin-top:0;
                padding:0 24px 100px;
                .side-nav{
                    width:100%;
                }
            }
            
        }
        .main-content-col{
            padding:24px 24px 100px ;
            width:100%;
            background-color:#F4F5F8;
            position:static;
            flex:1;
        }
    }

    @media screen and (max-width:$tabletPortrait) and (min-width: 768px){ 
        .main-nav{
            .main-navigation-wrap{
                .side-nav{
                    > li{
                        >a{
                            font-size:1.5rem;
                            svg{
                                width:1.9285rem;
                                max-width:1.9285rem;
                                max-height:1.9285rem;
                            }
                        }
                    }
                }
            }
        }
    }
}