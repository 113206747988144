.error-404{
    text-align:center;
    height:calc(100vh - 7.875rem);
    max-height:calc(100vh - 7.875rem);
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    .section-label{
        margin-bottom:1rem;
    }
    .site-button{
        margin-top: 1.5rem;
        height:2.625rem;
    }

    @media screen and (max-width:$tabletPortrait){
        min-height:200px;
        height:calc(100vh - 136px - 124px);
        max-height:calc(100vh - 136px - 124px);
    }
}