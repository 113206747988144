html{
    font-size:16px;
    -webkit-text-size-adjust:100%;

    @media screen and (min-width:1560px){
        font-size:108.3333%;
    }
    @media screen and (min-width:1680px){
        font-size:116%;
    }
    @media screen and (min-width:1800px){
        font-size:125%;
    }
    @media screen and (min-width:1920px){
        font-size:133.3333%;
    }
    @media screen and (max-width: 1440px){
        font-size: 100%; 
    }

    body{
        font-family:$roboto;
        &.no-scroll{
            overflow:hidden;
        }
        p{
            @include paragraph();
            color: $darkest;
            
        }
    }
}

