.rules-card{
    background-color:#FFF;
    box-shadow: 0px 3.40075px 6.8015px rgba(20, 23, 33, 0.04), 0px 0px 1.70037px rgba(20, 23, 33, 0.06), 0px 0px 0.850187px rgba(20, 23, 33, 0.04);
    padding: 2rem;
    .rule-name{
        margin-bottom: 0.75rem;
    }
    .rule-row{
        display:flex;
        font-size:1rem;
        line-height:1.4375;
        + .rule-row{
            margin-top:0.25rem;
        }
        .rule-label{
            font-weight:700;
            padding-right:0.5rem;
            white-space: nowrap;
        }
        .rule-data{
            a{
                display:inline-block;
                color:$blue;
                &:after{
                    content:',';
                }
                &:nth-child(n+2){
                    margin-left:0.25rem;
                }
                &:last-of-type{
                    &:after{
                        display:none;
                    }
                }
            }
        }
    }
    .rule-footer{
        padding-top:1rem;
        .view-details{
            @include arrowLink();
        }
    }
    @media screen and (max-width:$mobile){
        padding:20px;
        .rule-name{
            font-size:24px;
            margin-bottom:12px;
        }
        .rule-row{
            flex-direction:column;
            + .rule-row{
                margin-top:12px;
            }
        }
    }
}