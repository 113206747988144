.form-field fieldset {
    border-radius: 0;
  }

  .green-checkbox{
    &.Mui-checked{
      svg{
        color:$green !important;
      }
    }
  }

  .blue-checkbox{
    &.Mui-checked{
      color:$blue2 !important;
    }
  }

  .green-switch{
    .Mui-checked{
      color:$green !important;
      
      + .MuiSwitch-track{
        background-color:$green !important;
      }
    }
  }

.MuiAutocomplete-root{
  .MuiAutocomplete-tag{
    background-color: #E0E0E0;
    font-size: 0.8125rem;
    padding-left:10px;
    padding-right:10px;
    border-radius: 16px;
}
}