#order-sims-form{
    display:flex;
    .quantity-select-wrapper{
        flex:1;
        margin-right:1rem;
    }

    @media screen and (max-width:$mobile){
        .quantity-select-wrapper{
            margin-right:12px;
            .MuiInputLabel-formControl:not(.Mui-focused){
                line-height:1 !important;
                margin-top:-2px;
            }
            #sims-select{
                height:43px;
                max-height:43px;
                padding-top:0;
                padding-bottom:0;
            }
        }
    }
}