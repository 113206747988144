.account-view{
    .account-info-bar{
        margin-top:2rem;
        display:flex;
        gap: 1.5rem;
        .section-card{
            background-color:#FFF;
            box-shadow: 0px 3.40075px 6.8015px rgba(20, 23, 33, 0.04), 0px 0px 1.70037px rgba(20, 23, 33, 0.06), 0px 0px 0.850187px rgba(20, 23, 33, 0.04);
            width:40.3%;
            display:flex;
            flex-direction:column;
            .card-interior-row{
                padding:1.5rem;
                flex:1;
                display:flex;
                align-items:center;
                + .card-interior-row{
                    border-top:1px solid #E3E5E8;
                }
                .interior-wrap{
                    width:100%;
                }
            }
            .card-label{
                @include sectionLabel();
            }
            .accountholder{
                @include heading4();
            }
            .accountholder-details{
                opacity:0.75;
                color:$darkest;
                margin-top:0.5rem;
                font-size:1rem;
                line-height:1.5;
                .role{
                    text-transform:capitalize;
                }
            }
            .support-note{
                @include paragraph(1);
                color:$darkest;
                a{
                    opacity:0.75;
                }
            }
            .sims-count-row{
                display:flex;
                .count-item{
                    @include paragraph(1);
                    color:$darkest;
                    + .count-item{
                        margin-left: 4rem;
                    }
                    .count{
                        @include heading1();
                    }
                }
            }
        }
    }
    .account-users-section{
        margin-top: 2.5rem;
        .section-title{
            @include heading3();
        }
        .users-listing-wrap{
            margin-top:1.5rem;
            .responsive-table{
                .delete-user{
                    display:flex;
                    align-items:center;
                    cursor:pointer;
                }
            }
        }
        .add-user-row{
            margin-top:1rem;
            color:$blue;
            .add-user{
                display:inline-flex;
                align-items:center;
                cursor:pointer;
                svg{
                    height:1.5rem;
                    width:auto;
                    display:block;
                }
                span{
                    font-weight:500;
                    font-size: 0.9375rem;
                    margin-left:0.5rem;
                }
            }
        }
    }

    @media screen and (max-width:$smallDesktop){
        .account-info-bar{
            .section-card{
                width:50%;
            }
        }
    }
    @media screen and (max-width:$mobile){
        .account-info-bar{
            flex-direction:column;
            gap:20px;
            .section-card{
                width:100%;
                .card-interior-row{
                    padding:20px;
                }
            }
        }
        .account-users-section{
            margin-top:32px;
            .section-title{
                font-size:18px;
            }
        }
    }
}